<template>
  <div class="app-content">
    <el-card class="box-card" v-loading="loading">
      <div class="clearfix">
        <el-row>
          <el-col :span="24">
            <el-form :inline="true" @submit.native.prevent>
              <el-form-item label="企业名称">
                <el-input @change="onQuerySubmit" v-model="query.equipment_name" placeholder="企业名称"></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="onQuerySubmit">查询</el-button>
              </el-form-item>
            </el-form>
          </el-col>
        </el-row>
      </div>
      <div class="block">
        <el-row>
          <el-col :span="24">
            <el-table :data="res.data" stripe style="width: 100%">
              <el-table-column prop="enterprise_name" label="企业名称" min-width="200">
              </el-table-column>
              <el-table-column prop="equipment_name" label="排口名称" min-width="180">
              </el-table-column>
              <el-table-column prop="factor_name" label="最新故障因子" min-width="180">
              </el-table-column>
              <el-table-column prop="last_time" label="最新故障时间" min-width="200">
              </el-table-column>
              <el-table-column fixed="right" label="操作" width="200" v-if="permission.apiWarnEarlyHistory || permission.apiWarnEarlyLine">
                <template slot-scope="scope">
                  <el-button @click="logHistory(scope.row)" type="text" size="small" v-if="permission.apiWarnEarlyHistory">预警历史记录</el-button>
                  <el-button type="text" size="small" @click="logLine(scope.row)" v-if="permission.apiWarnEarlyLine">预警历史曲线</el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-col>
        </el-row>
        <el-pagination layout="prev, pager, next" prev-text="上一页" next-text="下一页" background :page-size="10" @current-change="changeCurrent" :current-page="query.page" :total="res.total"></el-pagination>
      </div>
    </el-card>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import { apiWarnEarlyNewest } from "src/api/index";
import md5 from 'js-md5';
export default {
  data() {
    return {
      loading: false,
      query: {
        equipment_name: '',
        page: 1,
        logotype: 'earlylog'
      },
      res: [],
      dayValue: '',
    };
  },
  mounted() {
    const _this = this;
    let query = JSON.parse(_this.$localStorage.get(md5('query')))
    if (query === null) {
      _this.query = _this.query
    } else if (query.logotype === 'earlylog') {
      _this.query =  query;
    } else {
      _this.$localStorage.remove(md5("query"));
    }
    _this.changeCurrent(_this.query.page);
    _this.pageInit(_this).then(() => {});
  },
  computed: mapState({
    url: (state) => state.url,
    permission: (state) => state.permission,
  }),
  watch: {
    '$route': 'getEarlyNewest'
  },
  methods: {
    ...mapActions(["pageInit"]),
    //查询企业预警信息
    onQuerySubmit() {
      this.query.page = 1;
      let route = this.$router.history.current.path + '?page=' + this.query.page + '&equipment_name=' + this.query.equipment_name;
      let locat = decodeURI(this.$router.history.current.fullPath);
      if (route === locat) {
        this.getEarlyNewest();
      } else {       
        this.$router.replace(route);
      }
    },
    //切换分页
    changeCurrent(item) {
      this.query.page = item;
      let route = this.$router.history.current.path + '?page=' + this.query.page + '&equipment_name=' + this.query.equipment_name;
      let locat = decodeURI(this.$router.history.current.fullPath);
      if (route === locat) {
        this.getEarlyNewest();
      } else {       
        this.$router.replace(route);
      }
    },
    //获取企业预警信息
    getEarlyNewest() {
      const _this = this;
      _this.loading = true;
      apiWarnEarlyNewest(_this.query)
      .then((res) => {
        _this.loading = false;
        _this.res = res;
      })
      .catch((err) => {
        _this.loading = false;
        if (err.message === '没有操作权限') {
          return
        } else {         
          _this.$message.error(err.message);
        }
      })
    },
    //跳转预警历史记录页
    logHistory(item) {
      this.$localStorage.set(md5("query"), JSON.stringify(this.query));
      this.$router.push('/admin/early/log-history/' + item.id)
    },
    //跳转预警历史曲线页
    logLine(item) {
      this.$localStorage.set(md5("query"), JSON.stringify(this.query));
      this.$router.push('/admin/early/log-line/' + item.id)
    },
  },
};
</script>
<style lang="less" scoped>
.app-content {
  background-color: #ffffff;
}
.el-pagination {
  text-align: center;
  margin-top: 27px;
}
.el-avatar--circle {
    position: relative;
    margin-top: 7px;
}
</style>